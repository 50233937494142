<template>
    <div id="users">
        <!-- 用户列表卡片区 -->
        <el-card class="box-card">
            <!--<el-form :inline="true" ref="form" :model="queryMap" label-width="70px" size="small">-->
                <!--<el-form-item label="Agent端末" label-width="100px">-->
                    <!--<el-input-->
                            <!--v-model="queryMap.name"-->
                            <!--placeholder="请输入Agent端末"-->
                            <!--clearable-->
                    <!--&gt;</el-input>-->
                <!--</el-form-item>-->
                <!--<el-form-item label="软件名" label-width="80px">-->
                    <!--<el-input-->
                            <!--v-model="queryMap.name"-->
                            <!--placeholder="请输入软件名"-->
                            <!--clearable-->
                    <!--&gt;</el-input>-->
                <!--</el-form-item>-->
                <!--<el-form-item style="margin-left:50px;">-->
                    <!--<el-button icon="el-icon-search">检索</el-button>-->
                <!--</el-form-item>-->
            <!--</el-form>-->

            <!-- 表格区域 -->
            <el-table v-loading="loading" size="small" :data="endList" :span-method="objectSpanMethod" border
                      style="width: 100%;"
                      height="600"
                      @cell-mouse-enter="handleCellMouseEnter"
                      @cell-mouse-leave="handleCellMouseLeave"
                      :row-class-name="tableRowClassName">
                <el-table-column prop="agentName" width="150" show-overflow-tooltip label="Agent端末Id"></el-table-column>
                <el-table-column prop="agentStatus" width="100" show-overflow-tooltip label="端末状态">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.agentStatus===true "type="success">在线</el-tag>
                        <el-tag v-if="scope.row.agentStatus===false" type="info">离线</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="UkeyName" width="150" show-overflow-tooltip label="UkeyId"></el-table-column>
                <el-table-column prop="softwareName" label="软件名" width="80"></el-table-column>
                <el-table-column prop="version" label="版本号" width="100"></el-table-column>
                <el-table-column label="赋权状况" width="100">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.empowerStatus=='granted'" type="success">赋权</el-tag>
                        <el-tag v-if="scope.row.empowerStatus=='ungrant'" type="warning">未赋权</el-tag>
                        <el-tag v-if="scope.row.empowerStatus=='overtime'" type="danger">已过期</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="申请状态" width="100">
                    <template slot-scope="scope">
                        <el-button size="small" v-if="(scope.row.applyStatus==null || scope.row.applyStatus==='unapply') && scope.row.softId != null" @click="applys(scope.row)" plain type="primary">申请</el-button>
                        <el-tag v-if="scope.row.applyStatus==='applying'" type="info">申请中</el-tag>
                        <el-tag v-if="scope.row.applyStatus==='applied' " type="success">已申请</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="endTime" width="150" show-overflow-tooltip label="使用期限"></el-table-column>
                <el-table-column label="操作" width="315">
                    <template slot-scope="scope">
                        <el-button size="small" v-if="scope.row.agentStatus==0" plain type="danger"
                                   @click="deleteAgent(scope.row.agentName)"
                                   icon="el-icon-delete">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-dialog :title="dialogTitle" class="class_dialog_hospital" @close="closeDialog"
                       :visible.sync="DialogVisible">
                <el-card class="box-card">
                    <el-form
                            :model="addForm"
                            :label-position="labelPosition"
                            :rules="addFormRules"
                            ref="addFormRef"
                            label-width="90px">
                        <el-form-item label="类别:" prop="type" label-width="90px">
                            <el-radio-group v-model="addForm.type" @change="editLabel">
                                <el-radio v-if="addForm.status===0" :label="0">试用</el-radio>
                                <el-radio :label="1">正式</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label-width="90px">
                            <el-table v-loading="loading" size="small" ref="multipleTable" :data="applyList" border style="width: 555px; "
                                      @selection-change="handleSelectionChange">
                                <el-table-column type="selection" width="50"></el-table-column>
                                <el-table-column prop="ukeyId" width="150" show-overflow-tooltip
                                                 label="Ukey"></el-table-column>
                                <el-table-column prop="agentId" width="150" show-overflow-tooltip
                                                 label="Agent端末"></el-table-column>
                                <el-table-column prop="grant" width="100" show-overflow-tooltip label="赋权状态">
                                    <template slot-scope="scope">
                                        <el-tag type="success" v-if="scope.row.grant==true">已赋权</el-tag>
                                        <el-tag type="info" v-if="scope.row.grant==false">未赋权</el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="dueDate" width="100" show-overflow-tooltip
                                                 label="使用期限"></el-table-column>
                            </el-table>
                        </el-form-item>
                        <el-form-item label="申请说明:" prop="applyExplain" label-width="90px">
                            <el-input type="textarea" v-model="addForm.applyExplain" maxlength="200" :rows="5"></el-input>
                        </el-form-item>
                    </el-form>
                </el-card>
                <span slot="footer" class="dialog-footer">
                   <el-button size="small" type="primary"
                              icon="el-icon-check" plain
                              @click="addApply()">申请
                   </el-button>
                   <el-button size="small" type="warning"
                              icon="el-icon-close" plain
                              @click="closeDialog">取消
                   </el-button>
             </span>
            </el-dialog>
        </el-card>
    </div>
</template>
<script>

    export default {
        data() {
            return {
                DialogVisible: false,
                addFormRules: {
                    applyExplain: [
                        {required: true, message: '请输入申请说明', trigger: 'blur'}],
                    type: [
                        {required: true, message: '请选择类别', trigger: 'blur'}
                    ],
                },
                addForm: {},
                dateValue: null,
                dialogTitle: '',
                endList: [],
                agent: [],
                options: [
                    {id: null, name: ""},
                    {id: 1, name: "未赋权"},
                    {id: 2, name: "赋权"},
                ],
                optionss: [
                    {id: null, name: ""},
                    {id: 1, name: "运行中"},
                    {id: 2, name: "未启动"},
                    {id: 3, name: "安装中"},
                ],
                addDialogVisible: false,
                loading: true,
                total: 0,
                //查询对象
                queryMap: {
                    startTime: "",
                    endTime: "",
                    currentPage: 1,
                    pageSize: 10,
                },
                currentIndex: null,
                labelPosition: "right",
                applyList:[],
                ukeyList:[],
                spanArr:[],
                pos:null
            };
        },

        methods: {

            /**
             * 删除
             */
            async deleteAgent(agentName) {
                const res = await this.$confirm(
                    "此操作为删除操作, 是否继续?",
                    "提示",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                    }
                );
                if (res === "confirm") {
                    let id=agentName;
                    const {data: res} = await this.$http.post("/factorymng/agent/delete/" + id);
                    if (res.success) {
                        this.$message.success("删除成功");
                        this.pageLoading()
                    } else {
                        this.$message.error(res.data != null ? res.data : res.data.errorMsg);
                    }
                }
            },

            /**
             * 添加申请
             */
            async addApply() {
                this.$refs.addFormRef.validate(async valid => {
                    if (!valid) {
                        return;
                    } else {
                        if (this.ukeyList.length == 0) {
                            return this.$notify.error({
                                title: '操作失败',
                                message: '请选择ukey'
                            });
                        } else {
                            this.addForm.ukeyId = this.ukeyList.toString();
                            const {data: res} = await this.$http.post("/factorymng/apply", this.addForm);
                            if (res.success) {
                                this.$notify.success({
                                    title: '操作成功',
                                    message: '添加成功',
                                });
                                this.addForm = {};
                                this.ukeyList=[];
                                this.pageLoading();
                            } else {
                                return this.$message.error(res.data != null ? res.data : res.data.errorMsg);
                            }
                        }}
                    this.DialogVisible=false;
                });
            },
            /**
             * 遍历
             **/
            handleSelectionChange(selection) {
                this.ukeyList = [];
                selection.forEach(item => {
                    this.ukeyList.push(item.ukeyId);
                });
            },

            /**
             * vue 强制刷新
             **/
            editLabel() {
                this.$forceUpdate();
            },

            /**
             * 加载端末列表
             **/
            async pageLoading() {
                this.endList = []
                this.spanArr=[]
                this.pos=null
                const {data: res} = await this.$http.get("/factorymng/agent");
                this.agent = res.data;
                for (let i = 0; i < this.agent.length; i++) {
                    if(this.agent[i].list!=null) {
                        for (let j = 0; j < this.agent[i].list.length; j++) {
                            let agentInfo = {
                                agentName: this.agent[i].id,
                                version: this.agent[i].list[j].version,
                                endTime: this.agent[i].list[j].endTime,
                                UkeyName: this.agent[i].ukeyId,
                                agentStatus: this.agent[i].online,
                                softwareName: this.agent[i].list[j].softName,
                                empowerStatus: this.agent[i].list[j].grantStatus,
                                applyStatus: this.agent[i].list[j].applyStatus,
                                softId: this.agent[i].list[j].softId,
                                status: this.agent[i].list[j].status,
                            }
                            this.endList.push(agentInfo)
                        }
                    }else {
                        let agentInfo = {
                            agentName: this.agent[i].id,

                            UkeyName: this.agent[i].ukeyId,
                            agentStatus: this.agent[i].online,

                        }
                        this.endList.push(agentInfo)
                    }
                }

                for (let i = 0; i < this.endList.length; i++) {
                    if (i == 0) {
                        this.spanArr.push(1);
                        this.pos = 0
                    } else {
                        // 判断当前元素与上一个元素是否相同
                        if (this.endList[i].agentName == this.endList[i - 1].agentName) {
                            this.spanArr[this.pos] += 1;
                            this.spanArr.push(0);
                        } else {
                            this.spanArr.push(1);
                            this.pos = i;
                        }
                    }
                }

            },

            /**
             * 申请列表
             **/
            async applys(row) {
                this.dialogTitle="申请"
                this.DialogVisible=true
                this.addForm=row
                this.addForm.type = 1;
                const {data: res} = await this.$http.get("/factorymng/ukey/canapply/list?softId="+row.softId+"&agentId="+row.agentName);
                this.applyList=res.data;
            },

            /**
             * 鼠标移入
             **/
            handleCellMouseEnter(row, column, rowIndex, columnIndex) {
                //标记当前是哪个分组
                this.currentIndex = row.agentName
            },

            /**
             * 鼠标移出
             **/
            handleCellMouseLeave() {
                //移出是清空光标
                this.currentIndex = ''
            },

            /**
             * 判断格式
             **/
            tableRowClassName({row, column, rowIndex, columnIndex}) {
                //逻辑判断决定是否修改样式，如果分组一致则使用同一个样式
                if (row.agentName === this.currentIndex) {
                    return 'hover-bg'
                } else {
                    return ''
                }
            },

            /**
             * 合并单元格
             **/
            objectSpanMethod({row, column, rowIndex, columnIndex}) {
                if (columnIndex === 0 ||columnIndex === 1 || columnIndex === 2 || columnIndex === 8) {
                    const _row = this.spanArr[rowIndex];
                    const _col = _row > 0 ? 1 : 0;
                    return {
                        rowspan: _row,
                        colspan: _col
                    };
                }
            },

            /**
             * 重置
             */
            reset() {
                this.queryMap = {
                    currentPage: 1,
                    pageSize: 10,
                };
            },

            /**
             * 关闭添加弹出框
             */
            closeDialog() {
                this.$refs.addFormRef.clearValidate();
                this.DialogVisible = false;
                this.addForm = {};
                this.$refs.multipleTable.clearSelection();
            },

            /**
             *  改变页码
             */
            handleSizeChange(newSize) {
                this.queryMap.pageSize = newSize;

            },
            /**
             * 翻页
             */
            handleCurrentChange(current) {
                this.queryMap.currentPage = current;
            },
        },

        created() {
            this.pageLoading();
            setTimeout(() => {
                this.loading = false;
            }, 500);
        }
    };
</script>


<style>
    .el-table__body .el-table__row.hover-bg td {
        background-color: #F5F7FA;
    }

</style>
